<h1 mat-dialog-title>Add Clinic Details</h1>
<div mat-dialog-content>
  <div class="modal-content-wrap">
    <form [formGroup]="clinicAddForm">
      <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label for="">Clinic  Name</label>
          <input type="text" formControlName="clinic_name" />
        </div>

        <div>
          <label for=""> Phone No. </label>
          <input type="text" formControlName="phone" />
        </div>
        <div>
          <label for="">Clinic Location</label>
          <input type="text" formControlName="location" />
        </div>
      </div>
      <div class="w-full py-3">
        <mat-slide-toggle formControlName="status">Active</mat-slide-toggle>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="items-center justify-end gap-2">
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button *ngIf="!editMode" mat-button class="btn-primary" (click)="clinicAddFormSubmit()">
    Add
  </button>
  <button *ngIf="editMode" mat-button class="btn-primary" (click)="clinicEditFormSubmit()">
    Update
  </button>
</div>
