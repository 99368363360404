import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

import { TableColumn } from "src/@vex/interfaces/table-column.interface";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "byon-clinic-stats",
  templateUrl: "./clinic-stats.component.html",
  styleUrls: ["./clinic-stats.component.scss"],
})
export class ClinicStatsComponent implements OnInit, AfterViewInit {
  @Input() data: any[];
  @Input() columns: TableColumn<any>[] = [
    {
      label: "Test Name",
      property: "test",
      type: "text",
    },
    {
      label: "Total Test",
      property: "count",
      type: "text",
      cssClasses: ["text-secondary"],
    },
  ];
  items = [
    {
      value: 7,
      viewValue: "Weekly",
    },
    {
      value: 30,
      viewValue: "Monthly",
    },
    {
      value: 365,
      viewValue: "Yearly",
    },
  ];
  @Input() pageSize = 6;
  AnalysisPeriod;
  visibleColumns: Array<keyof any | string>;
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dash: DashboardService) {}

  ngOnInit() {
    this.AnalysisPeriod = 365;
    this.visibleColumns = this.columns.map((column) => column.property);
    this.getStatsData(this.AnalysisPeriod);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  NestedObjectParse(column: TableColumn<any>, row: any) {
    const val = column.property.includes(".")
      ? column.property.split(".").reduce((r, val) => {
          return r ? r[val] : undefined;
        }, row)
      : row[column.property];
    return val;
  }

  getStatsData(days) {
    this.dash.getTopTestStats(days).subscribe({
      next: (res: any) => {
        this.dataSource.data = res.result;
        console.table(res.result);
      },
    });
  }
}
