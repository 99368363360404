import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddPatientDialogComponent } from "../../components/add-patient-dialog/add-patient-dialog.component";
import { PatientTableComponent } from "../../components/patient-table/patient-table.component";

@Component({
  templateUrl: "./patients.component.html",
  styleUrls: ["./patients.component.scss"],
})
export class PatientsComponent implements OnInit {
  animal: string;
  name: string;
  @ViewChild(PatientTableComponent) child: PatientTableComponent;
  constructor(public dialog: MatDialog) {}
  openDialog(): void {
    const dialogRef = this.dialog.open(AddPatientDialogComponent, {
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.child.getData();
    });
  }
  ngOnInit(): void {}
}
