import { UserTableComponent } from "./../../components/user-table/user-table.component";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddUserModalComponent } from "../../components/add-user-modal/add-user-modal.component";

@Component({
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  animal: string;
  name: string;
  @ViewChild(UserTableComponent) child: UserTableComponent;

  constructor(public dialog: MatDialog) {}
  openDialog(): void {
    const dialogRef = this.dialog.open(AddUserModalComponent, {
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.child.getData();
    });
  }
  ngOnInit(): void {}
}
