<h1 mat-dialog-title>Add Patient Details</h1>
<div mat-dialog-content>
  <div class="modal-content-wrap">
    <form [formGroup]="patientAddForm">
      <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label for="">First Name</label>
          <input type="text" formControlName="first_name" />
        </div>
        <div>
          <label for="">Last Name</label>
          <input type="text" formControlName="last_name" />
        </div>
        <div>
          <label for=""> Phone No. </label>
          <input type="text" formControlName="phone" />
        </div>
        <div>
          <label for="">Gender</label>
          <mat-select class="selection max-w-[-webkit-fill-available]" formControlName="gender"
              placeholder=""
            >
              <mat-option value="Male"  >
                Male
              </mat-option>
              <mat-option value="Female"  >
                Female
              </mat-option>
          </mat-select>

        </div>
        <div>
          <label for="">Patient Age</label>
          <input type="text" formControlName="age" />
        </div>
        <div>
          <label for="">Patient Code</label>
          <input type="text" formControlName="patient_code" />
        </div>
      </div>
      <div class="w-full py-3">
        <mat-slide-toggle formControlName="status">Active</mat-slide-toggle>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="items-center justify-end gap-2">
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button *ngIf="!editMode" mat-button class="btn-primary" (click)="patientAddFormSubmit()">
    Add
  </button>
  <button *ngIf="editMode" mat-button class="btn-primary" (click)="patientEditFormSubmit()">
    Update
  </button>
</div>
