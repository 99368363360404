<div class="flex items-center justify-between">
  <h1 class="text-2xl">Test Reports</h1>
  <div class="flex gap-2">
    <input
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      class="hidden-input hidden"
      (change)="onFileSelected($event)"
      #fileUpload
    />

    <div class="file-upload">
      <button class="mat-button btn flex items-center" (click)="fileUpload.click()">
        <mat-icon class="text-xl">attach_file</mat-icon> &nbsp;Upload Test
        Report
      </button>
    </div>

    <button class="mat-button btn-primary" (click)="openDialog()">
      Add Test Report
    </button>
  </div>
</div>
<div class="row flex w-full flex-col sm:flex-row mt-4 gap-2"></div>
<div class="w-full">
  <byon-test-report-table></byon-test-report-table>
</div>
