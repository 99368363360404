import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TableColumn } from "src/@vex/interfaces/table-column.interface";
import { TestService } from "src/app/modules/settings/services/test.service";

@Component({
  selector: "byon-recent-test-table",
  templateUrl: "./recent-test-table.component.html",
  styleUrls: ["./recent-test-table.component.scss"],
})
export class RecentTestTableComponent<T>
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() data: T[];
  @Input() columns: TableColumn<T>[];
  @Input() pageSize = 6;

  visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _test: TestService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.visibleColumns = this.columns.map((column) => column.property);
    }

    if (changes.data) {
      this.dataSource.data = this.data?.slice(6);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  NestedObjectParse(column: TableColumn<any>, row: any) {
    const val = column.property.includes(".")
      ? column.property.split(".").reduce((r, val) => {
          return r ? r[val] : undefined;
        }, row)
      : row[column.property];
    return val;
  }
}
