<h1 mat-dialog-title>Add Patient Details</h1>
<div mat-dialog-content>
  <div class="modal-content-wrap">
    <form [formGroup]="useraddForm">
      <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
        <div class="md:col-start-1 md:col-end-3">
          <label for="">Full Name</label>
          <input formControlName="full_name" type="text" />
        </div>
        <div>
          <label for=""> E-mail </label>
          <input formControlName="email" type="email" email />
        </div>
        <!-- <div>
          <label for="">Phone No.</label>
          <input formControlName="phone" type="text" />
        </div> -->
        <div>
          <label for="">Password</label>
          <div class="relative">
            <input
              formControlName="password"
              placeholder=""
              [type]="show ? 'password' : 'text'"
              onClick="this.setSelectionRange(0, this.value.length)"
            />
            <div
              class="absolute inset-y-0 right-0 flex items-center text-sm leading-5 px-2 opacity-50 cursor-pointer border-l border-gray-300 select-none"
            >
              <div
                class="border-r border-gray-300 h-full flex items-center"
                (click)="generatePassword()"
              >
                <mat-icon
                  class="w-5 mr-2"
                  svgIcon="mat:build"
                  title="Generate Password"
                ></mat-icon>
              </div>
              <div class="ml-2 h-full flex items-center" (click)="show = !show">
                <mat-icon
                  *ngIf="show"
                  class="w-5"
                  svgIcon="mat:visibility"
                  title="Show Password"
                ></mat-icon>
                <mat-icon
                  *ngIf="!show"
                  class="w-5"
                  svgIcon="mat:visibility_off"
                  title="Hide Password"
                ></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4 py-2">
        <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
        <mat-slide-toggle formControlName="is_staff"
          >ByonCare Staff</mat-slide-toggle
        >
        <mat-slide-toggle formControlName="is_superuser"
          >Admin</mat-slide-toggle
        >
      </div>
    </form>
    <div
      class="w-full border rounded-md border-red-300 bg-red-100 text-red-300 my-2 py-2 px-3"
      *ngIf="userAddError"
    >
      {{ userAddError }}
    </div>
  </div>
</div>
<div mat-dialog-actions class="items-center justify-end gap-2">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button *ngIf="!editMode" mat-button class="btn-primary" (click)="userAddFormSubmit()">
    Add
  </button>
  <button *ngIf="editMode" mat-button class="btn-primary" (click)="userEditFormSubmit()">
    Update
  </button>
</div>
