<div class="card overflow-hidden w-full flex flex-col">
  <div class="border-b py-4 px-6 flex items-center">
    <h2 class="m-0 title flex-auto">Recent Tests</h2>

    <button mat-icon-button type="button">
      <!-- <mat-icon class="text-secondary" svgIcon="mat:cloud_download"></mat-icon> -->
    </button>

    <button
      routerLink="/test-reports"
      mat-button
      type="button"
      class="text-rose-500"
    >
      View All
      <!-- <mat-icon class="text-secondary" svgIcon="mat:more_horiz"></mat-icon> -->
    </button>
  </div>

  <div class="overflow-auto p-4">
    <table
      [dataSource]="dataSource"
      class="w-full border rounded"
      mat-table
      matSort
    >
      <ng-container *ngFor="let column of columns">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            class="bg-slate-100"
          >
            {{ column.label }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ NestedObjectParse(column, row) }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.type === 'badge'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ column.label }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              *ngIf="row[column.property] === 'ready'"
              class="w-3 h-3 rounded-full bg-green cursor-pointer"
              matTooltip="Ready to ship"
            ></div>
            <div
              *ngIf="row[column.property] === 'pending'"
              class="w-3 h-3 rounded-full bg-orange cursor-pointer"
              matTooltip="Pending Payment"
            ></div>
            <div
              *ngIf="row[column.property] === 'warn'"
              class="w-3 h-3 rounded-full bg-red cursor-pointer"
              matTooltip="Missing Payment"
            ></div>
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
    </table>
  </div>
</div>
