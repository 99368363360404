import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ClinicService {
  constructor(private httpClient: HttpClient) {}

  getAllClinics(search?) {
    return this.httpClient.get(
      `${environment.apiUrl}clinics/list/?clinic_name=${search || ""}`
    );
  }
  getClinicsById(id) {
    return this.httpClient.get(`${environment.apiUrl}clinics/${id}/`);
  }
  addClinic(Clinic) {
    return this.httpClient.post(`${environment.apiUrl}clinics/`, Clinic);
  }
  updateClinic(Clinic, clinicId) {
    return this.httpClient.put(
      `${environment.apiUrl}clinics/${clinicId}/`,
      Clinic
    );
  }
}
