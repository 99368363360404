import { AddTestReportDialogComponent } from "./../add-test-report-dialog/add-test-report-dialog.component";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { stagger40ms } from "src/@vex/animations/stagger.animation";
import { TableColumn } from "src/@vex/interfaces/table-column.interface";
import { MatSelectChange } from "@angular/material/select";
import { AddTestDialogComponent } from "../add-test-dialog/add-test-dialog.component";
import { MatTableDataSource } from "@angular/material/table";
import {
  Observable,
  ReplaySubject,
  catchError,
  filter,
  map,
  of,
  switchMap,
} from "rxjs";
import {
  HTTPResultArrayPaginated,
  Report,
} from "src/app/modules/settings/interfaces/test.interface";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TestService } from "src/app/modules/settings/services/test.service";
import { MatDialog } from "@angular/material/dialog";
import { statusList } from "../../data/patient-table.data";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { Params } from "@angular/router";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "byon-test-report-table",
  templateUrl: "./test-report-table.component.html",
  styleUrls: ["./test-report-table.component.scss"],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "standard",
      } as unknown as MatFormFieldDefaultOptions,
    },
  ],
})
export class TestReportTableComponent implements OnInit {
  layoutCtrl = new UntypedFormControl("fullwidth");
  subject$: ReplaySubject<Report[]> = new ReplaySubject<Report[]>(1);
  data$: Observable<Report[]> = this.subject$.asObservable();
  customers: Report[];

  allTests: [];
  @Output() StatusFilterEmitter = new EventEmitter<number>();
  StatusFilter = null;

  @Input()
  columns: TableColumn<Report>[] = [
    {
      label: "No.",
      property: "id",
      type: "id",
      visible: false,
      cssClasses: ["font-medium"],
    },
    {
      label: "Patient Id",
      property: "patient_id.patient_code",
      type: "text",
      visible: true,
      cssClasses: ["text-secondary", "font-medium"],
    },
    {
      label: "Date",
      property: "test_date",
      type: "date",
      visible: true,
      cssClasses: ["text-secondary", "font-medium"],
    },
    {
      label: "Name",
      property: "patient_id.first_name",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Clinic Name",
      property: "clinic_id.clinic_name",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Location",
      property: "clinic_id.location",
      type: "text",
      visible: false,
      cssClasses: ["font-medium"],
    },
    {
      label: "Test",
      property: "test_id.test_name",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Result",
      property: "result",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Observation",
      property: "observation",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Phone",
      property: "patient_id.phone",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Gender",
      property: "patient_id.gender",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "actions",
      property: "actions",
      type: "button",
      visible: true,
      cssClasses: ["font-medium"],
    },
  ];
  pageSize = 10;
  currentPage;
  params: Params;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<Report> | null;
  selection = new SelectionModel<Report>(true, []);
  searchCtrl = new UntypedFormControl();
  length = 0;
  dataSource$ = new Observable<Report[]>();
  status = statusList;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private testService: TestService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  ngOnInit() {
    this.dataSource = new MatTableDataSource<Report>();

    this.data$.pipe(filter<Report[]>(Boolean)).subscribe((customers) => {
      this.customers = customers;
      this.dataSource.data = customers;
    });
    this.getData();
    this.searchCtrl.valueChanges.subscribe((value) =>
      this.onFilterChange(value)
    );
  }

  getTableData$(pageNumber: Number, pageSize: Number, filters) {
    return this.testService.getTestReports(pageNumber, pageSize, filters);
  }

  getData() {
    this.dataSource$ = this.route.queryParams.pipe(
      switchMap((params: Params) => {
        this.params = params;

        const filters = {
          test_names: params.test_names || "",
          patient_ids: params.patient_ids || "",
          patient_phones: params.patient_phones || "",
          clinic_names: params.clinic_names || "",
          test_date: params.test_date || "",
        };

        return this.getTableData$(
          params.page || 1,
          this.pageSize,
          filters
        ).pipe(
          map((data: HTTPResultArrayPaginated<Report>) => {
            this.length = data.count;
            this.paginator.pageIndex = params.page - 1 || 0;
            return data.results.map((element) => ({
              ...element,
              isExpanded: false,
            }));
          }),
          catchError(() => {
            this.length = 0;
            return of(null);
          })
        );
      })
    );
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = this.params.page - 1 || 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(() => {
      this.router.navigate(["/test-reports"], {
        relativeTo: this.route,
        queryParams: { page: this.paginator.pageIndex + 1 },
        queryParamsHandling: "merge",
      });
    });
  }

  NestedObjectParse(column: TableColumn<Report>, row: any) {
    const val = column.property.includes(".")
      ? column.property.split(".").reduce((r, val) => {
          return r ? r[val] : undefined;
        }, row)
      : row[column.property];
    return val;
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: Report) {
    const index = this.customers.findIndex((c) => c === row);
    // this.customers[index].status = change.value;
    this.subject$.next(this.customers);
  }

  deleteTest(id: number) {
    this.testService.deleteTest(id).subscribe({
      next: (res) => {
        // this.getData();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  openDialog(id: number): void {
    const dialogRef = this.dialog.open(AddTestReportDialogComponent, {
      data: id,
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }
}
