import { environment } from "./../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User, UserNew } from "../interfaces/customer.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private httpClient: HttpClient) {}
  getTestTypeById(id: number) {
    return this.httpClient.get<User>(`${environment.apiUrl}users/${id}/`);
  }

  UpdateTestSection(User: any, id: number) {
    return this.httpClient.put(`${environment.apiUrl}users/${id}/edit/`, User);
  }

  getAllUsers() {
    return this.httpClient.get<User[]>(`${environment.apiUrl}users/`);
  }

  addNewUser(user: UserNew) {
    return this.httpClient.post(`${environment.apiUrl}users/add/`, user);
  }

  deleteUser(userId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}users/${userId}/delete/`
    );
  }
}
