import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "vex-info-card",
  templateUrl: "./info-card.component.html",
  styleUrls: ["./info-card.component.scss"],
})
export class InfoCardComponent implements OnInit {
  @Input() cardTitle = "";
  @Input() value = 0;
  @Input() cardImgSrc = "";
  @Input() link = "";

  linkPrefix(link: string) {
    return `/${link}`;
  }

  constructor() {}

  ngOnInit(): void {}
}
