<main class="w-full min-h-fit h-full md:h-full md:flex md:flex-row overflow-y-auto md:overflow-hidden">
  <div class="min-h-fit h-full md:h-full w-full md:w-1/2 p-8">
    <div class="text-3xl flex items-center gap-4">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-12 select-none flex-none" />
      ByonCare
    </div>
    <div class="w-full min-h-fit h-full md:p-6 md:pb-32">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="md:w-1/2 hidden md:flex bg-red-orange flex-col items-center justify-center">
    <h3 class="text-xl md:text-2xl text-white md:mb-8 mt-16 md:mt-0 p-4">
      Access all your medical reports with a single touch.
    </h3>
    <img class="h-fit scale-[0.65] md:scale-[1]" src="assets/img/illustrations/auth-bg.svg" alt="" />
  </div>
</main>
