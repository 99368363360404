<ng-template #sidenavRef>
  <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar
    (collapse)="(sidenavCollapsed$)"
    [hasShadow]="toolbarShadowEnabled$ | async"
    [mobileQuery]="!(isDesktop$ | async)"
    class="vex-toolbar"
  ></vex-toolbar>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

<vex-layout
  [quickpanelRef]="quickpanelRef"
  [sidenavRef]="sidenavRef"
  [toolbarRef]="toolbarRef"
></vex-layout>
