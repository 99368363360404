<div class="card overflow-auto w-full">
  <div class="px-6 min-h-16 sticky left-0 flex flex-col md:flex-row md:items-center h-fit gap-y-2 md:gap-none my-2">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
        <span *ngIf="selection.selected.length > 1">s</span> selected</span>
    </h2>

    <span class="flex-1"></span>
    <div class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent focus:outline-none"
        placeholder="Search..." type="search" />
    </div>
  </div>
  <div class="w-full px-4">
    <table @stagger [dataSource]="dataSource$" class="w-full" mat-table matSort>
      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <!-- Image Column -->
      <ng-container matColumnDef="image">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell></td>
      </ng-container>
      <!-- Id Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'id'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase bg-slate-100" mat-header-cell mat-sort-header>
            {{ column.label }}
          </th>
          <td *matCellDef="let row ;let i = index" [ngClass]="column.cssClasses" mat-cell>
            {{ i + 1 }}
          </td>
        </ng-container>
      </ng-container>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase bg-slate-100" mat-header-cell mat-sort-header>
            {{ column.label }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Date Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase bg-slate-100" mat-header-cell mat-sort-header>
            {{ column.label }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Label Column -->

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef class="uppercase bg-slate-100" mat-header-cell mat-sort-header>
          Status
        </th>
        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()" class="flex items-center gap-1">
            <byon-status-indicator [statusName]="row.status?'Active':'Disabled'" [color]="row.status?'green':'red'">
            </byon-status-indicator>
            <!-- <mat-slide-toggle color="accent" [ngModel]="row.status">
            </mat-slide-toggle> -->
          </div>
        </td>
      </ng-container>
      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell class="bg-slate-100"></th>
        <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
          <!-- [matMenuTriggerFor]="actionsMenu" -->
          <!-- [matMenuTriggerData]="{ customer: row }" -->
          <button (click)="openDialog(row.id)" mat-icon-button type="button">
            <mat-icon svgIcon="mat:edit"></mat-icon>
          </button>
          <button (click)="deleteUser(row.id)" mat-icon-button type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <!-- (click)="updateCustomer(row)"  -->
      <tr *matRowDef="let row; columns: visibleColumns" @fadeInUp class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row></tr>
    </table>
  </div>
  <mat-paginator showFirstLastButtons [length]="length" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
    class="sticky left-0"></mat-paginator>
</div>
