import {
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";
import { ApexOptions } from "src/@vex/components/chart/chart.component";
import { TableColumn } from "src/@vex/interfaces/table-column.interface";
import { createDateArray } from "src/@vex/utils/create-date-array";
import { defaultChartOptions } from "src/@vex/utils/default-chart-options";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "byon-patient-stats",
  templateUrl: "./patient-stats.component.html",
  styleUrls: ["./patient-stats.component.scss"],
})
export class PatientStatsComponent implements OnInit, AfterViewInit {
  options: ApexOptions = defaultChartOptions({
    grid: {
      show: true,
      strokeDashArray: 3,
      padding: {
        left: 16,
      },
    },
    chart: {
      type: "bar",
      height: 352,
      sparkline: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: `30%`,
      },
    },
    fill: {
      type: "color",
      colors: ["#1DBEC3", "#FE8919"],
    },
    colors: ["#1DBEC3", "#FE8919"],
    labels: createDateArray(7),
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    legend: {
      show: true,
      itemMargin: {
        horizontal: 4,
        vertical: 4,
      },
    },
  });

  AnalysisPeriod;
  items = [
    {
      value: 7,
      viewValue: "Weekly",
    },
    {
      value: 30,
      viewValue: "Monthly",
    },
    {
      value: 365,
      viewValue: "Yearly",
    },
  ];

  userSessionsSeries: ApexAxisChartSeries = [];

  constructor(private dash: DashboardService) {}

  ngOnInit(): void {
    this.AnalysisPeriod = 7;
    this.getStatsByGender(this.AnalysisPeriod);
  }
  ngAfterViewInit(): void {}

  getStatsByGender(days) {
    this.dash.getPatientGenderedStats(days).subscribe({
      next: (res: any) => {
        this.options.labels = createDateArray(res.totalDataPoints);
        this.options.series = res.data;
        const object = Object.assign({}, this.options);
        this.options = object;
      },
    });
  }
}
