import { environment } from "./../../environments/environment";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private jwtHelper: JwtHelperService,
    private router: Router,
    private http: HttpClient
  ) {}

  loginNow(token: string) {
    localStorage.setItem("token", token);
  }

  logoutNow() {
    localStorage.clear();
    this.router.navigate(["login"]);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    return token.length > 0;
  }

  login(login) {
    return this.http.post(`${environment.apiUrl}login/`, login);
  }
}
