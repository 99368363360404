import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, Subscription } from "rxjs";
import { PatientService } from "../../services/patient.service";

@Component({
  selector: "byon-add-patient-dialog",
  templateUrl: "./add-patient-dialog.component.html",
  styleUrls: ["./add-patient-dialog.component.scss"],
})
export class AddPatientDialogComponent implements OnInit, OnDestroy {
  AddPatientSub: Subscription | undefined;
  EditPatientSub: Subscription | undefined;
  patientAddError: string | null = null;
  editMode = false;

  constructor(
    public dialogRef: MatDialogRef<AddPatientDialogComponent>, // @Inject(MAT_DIALOG_DATA) public data: DialogData
    private patientServive: PatientService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public patientId?: number
  ) {}

  patientAddForm = new FormGroup({
    first_name: new FormControl(""),
    last_name: new FormControl(""),
    patient_code: new FormControl(""),
    phone: new FormControl(""),
    gender: new FormControl(""),
    age: new FormControl(""),
    status: new FormControl(true),
  });

  onNoClick(): void {
    this.dialogRef.close();
  }

  patientAddFormSubmit() {
    this.addPatient(this.patientAddForm.value);
  }

  addPatient(patient) {
    this.AddPatientSub = this.patientServive.addPatient(patient).subscribe({
      next: (res) => {
        this.onNoClick();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  patientEditFormSubmit() {
    this.updatePatient(this.patientAddForm.value);
  }

  updatePatient(testType) {
    this.EditPatientSub = this.patientServive
      .updatePatient(testType, this.patientId)
      .subscribe({
        next: (res) => {
          this.onNoClick();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnInit(): void {
    if (this.patientId)
      this.patientServive.getPatient(this.patientId).subscribe({
        next: (res: any) => {
          this.editMode = true;
          console.log(res);
          this.patientAddForm.patchValue(res.result);
        },
      });
  }

  static open(dialog: MatDialog, id: string): Observable<boolean> {
    return dialog
      .open(AddPatientDialogComponent, { data: id, disableClose: true })
      .afterClosed();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  ngOnDestroy(): void {
    this.AddPatientSub?.unsubscribe();
  }
}
