import { DashboardCardListItem } from "../modules/dashboard/interface/dashboard.iterface";

export const DashboardCardList: DashboardCardListItem[] = [
  {
    cardName: "Total Tests",
    value: 8,
    icon: "assets/img/icons/dash/test-total.svg",
    link: "test-reports",
  },
  {
    cardName: "Total Clinics",
    value: 213,
    icon: "assets/img/icons/dash/clinic.svg",
    link: "clinics",
  },
  {
    cardName: "Registered Patients",
    value: 120,
    icon: "assets/img/icons/dash/registered-patients.svg",
    link: "patients",
  },
  {
    cardName: "Total Issues",
    value: 9,
    icon: "assets/img/icons/dash/waiting.svg",
    link: "test-reports-issues",
  },
];
