import { filter } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HTTPResult } from "../interfaces/test.interface";
import { TestReport } from "../../dashboard/interfaces/test-report.interface";

@Injectable({
  providedIn: "root",
})
export class TestService {
  constructor(private http: HttpClient) {}

  getTestTypes() {
    return this.http.get(`${environment.apiUrl}test-sections/list`);
  }
  addTestSection(testSection: any) {
    return this.http.post(`${environment.apiUrl}test-sections/`, testSection);
  }
  UpdateTestSection(testSection: any, testSectionId) {
    return this.http.put(
      `${environment.apiUrl}test-sections/${testSectionId}/`,
      testSection
    );
  }
  deleteTestSection(id: any) {
    return this.http.delete(`${environment.apiUrl}test-sections/${id}/`);
  }

  getTestTypeById(testId: number) {
    return this.http.get(`${environment.apiUrl}test-sections/${testId}/`);
  }
  getTest(page, pageSize, testName?) {
    return this.http.get(
      `${environment.apiUrl}tests/list/?test_name=${testName || ""}`
    );
  }
  addTest(test: any) {
    return this.http.post(`${environment.apiUrl}test/`, test);
  }
  UpdateTest(test: any, testId) {
    return this.http.put(`${environment.apiUrl}test/${testId}/`, test);
  }
  deleteTest(id: any) {
    return this.http.delete(`${environment.apiUrl}test/${id}/`);
  }

  getTestById(testId: number) {
    return this.http.get(`${environment.apiUrl}test/${testId}/`);
  }
  getTestReportById(TestReportId: number) {
    return this.http.get<HTTPResult<TestReport>>(
      `${environment.apiUrl}patient-test/${TestReportId}/`
    );
  }
  editTestReportById(TestReportId: number, data) {
    return this.http.put(
      `${environment.apiUrl}patient-test/${TestReportId}/`,
      data
    );
  }

  getTestReports(pageNumber, pageSize, filtes?) {
    let params: any = "";
    if (filtes) {
      const filters = {
        test_names: filtes.test_names || "",
        patient_ids: filtes.patient_ids || "",
        patient_phones: filtes.patient_phones || "",
        clinic_names: filtes.clinic_names || "",
        test_date: filtes.test_date || "",
      };
      params = new HttpParams({ fromObject: filters });
    } else {
    }
    return this.http.get(
      `${environment.apiUrl}patient-tests/list/?page=${pageNumber}&${params}`
    );
  }

  getTestReportsIssues(pageNumber, pageSize, filtes?) {
    let params: any = "";
    if (filtes) {
      const filters = {
        test_names: filtes.test_names || "",
        patient_ids: filtes.patient_ids || "",
        patient_phones: filtes.patient_phones || "",
        clinic_names: filtes.clinic_names || "",
        test_date: filtes.test_date || "",
      };
      params = new HttpParams({ fromObject: filters });
    } else {
    }
    return this.http.get(
      `${environment.apiUrl}pending-patient-tests/list/?page=${pageNumber}&${params}`
    );
  }

  addPatientTest(patientTest) {
    return this.http.post(
      `${environment.apiUrl}patient-test-add/`,
      patientTest
    );
  }

  testReportUploadBulk(file) {
    return this.http.post(`${environment.apiUrl}patient-tests/bulkupload/`, file);
  }
}
