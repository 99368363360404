<h1 mat-dialog-title>Add Test Details</h1>
<div mat-dialog-content>
  <div class="modal-content-wrap">
    <form [formGroup]="testAddForm">
      <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label for="">Date</label>
          <div class="flex border border-gray-200 rounded">
            <input
              formControlName="test_date"
              class="border-0"
              matInput
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div>
          <label for="">Existing Patient</label>

          <mat-select
            [(ngModel)]="currentPatient"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
            (ngModelChange)="changePatientDetails()"
            placeholder="Patients"
            class="selection max-w-[-webkit-fill-available]"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Find Patients..."
                noEntriesFoundLabel="'No Matches Found'"
                [formControl]="patientFilterCtrl"
              >
                <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let patient of filteredPatient | async"
              [value]="patient"
            >
              {{ patient?.first_name }} {{ patient?.last_name }}
            </mat-option>
          </mat-select>
          <!--  <mat-select
            class="selection max-w-[-webkit-fill-available]"
            [(ngModel)]="currentPatient"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
            (ngModelChange)="changePatientDetails()"
          >
            <mat-option [value]="patient" *ngFor="let patient of PatientData">
              {{ patient.first_name }} {{ patient.last_name }}
            </mat-option>
          </mat-select> -->
        </div>
        <div>
          <label for="">First Name</label>
          <input type="text" formControlName="FirstName" />
        </div>
        <div>
          <label for="">Last Name</label>
          <input type="text" formControlName="LastName" />
        </div>
        <div>
          <label for="">Age</label>
          <input type="text" formControlName="Age" />
        </div>
        <div>
          <label for="">Gender</label>
          <mat-select
            class="selection max-w-[-webkit-fill-available]"
            formControlName="Gender"
            placeholder=""
          >
            <mat-option value="Male"> Male </mat-option>
            <mat-option value="Female"> Female </mat-option>
          </mat-select>
        </div>

        <div>
          <label for="">Test</label>
          <mat-select
            class="selection max-w-[-webkit-fill-available]"
            formControlName="Test"
            placeholder=""
            (ngModelChange)="changeTestDetails()"
          >
            <mat-option
              [value]="testType.test_name"
              *ngFor="let testType of dataSource"
            >
              {{ testType.test_name }}
            </mat-option>
          </mat-select>
        </div>
        <div>
          <label for="">Clinic</label>
          <mat-select
            class="selection max-w-[-webkit-fill-available]"
            formControlName="ClinicName"
            placeholder=""
            (ngModelChange)="changeTestDetails()"
          >
            <mat-option
              [value]="clinic.clinic_name"
              *ngFor="let clinic of ClinicData"
            >
              {{ clinic.clinic_name }}
            </mat-option>
          </mat-select>
        </div>
        <div>
          <label for="">Phone</label>
          <input type="text" formControlName="Telephone" />
        </div>
        <div>
          <label for="">Result</label>
          <input type="text" formControlName="Result" />
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="items-center justify-end gap-2">
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button
    *ngIf="!editMode"
    mat-button
    class="btn-primary"
    (click)="testAddFormSubmit()"
  >
    Add
  </button>
  <button
    *ngIf="editMode"
    mat-button
    class="btn-primary"
    (click)="testEditFormSubmit()"
  >
    Update
  </button>
</div>
