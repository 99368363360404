import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, ReplaySubject, Subscription, filter } from 'rxjs';
import { TestSection } from 'src/app/modules/settings/interfaces/test.interface';
import { TestService } from 'src/app/modules/settings/services/test.service';

@Component({
  selector: 'byon-add-test-dialog',
  templateUrl: './add-test-dialog.component.html',
  styleUrls: ['./add-test-dialog.component.scss']
})
export class AddTestDialogComponent implements OnInit {

  AddTestSub: Subscription | undefined;
  EditTestSub: Subscription | undefined;
  TestAddError: string | null = null;
  editMode = false;
  subject$: ReplaySubject<TestSection[]> = new ReplaySubject<TestSection[]>(1);
  data$: Observable<TestSection[]> = this.subject$.asObservable();
  dataSource

  constructor(
    public dialogRef: MatDialogRef<AddTestDialogComponent>, // @Inject(MAT_DIALOG_DATA) public data: DialogData
    private testService: TestService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public testId?: number
  ) { }


  testAddForm = new FormGroup({
    test_name: new FormControl(""),
    test_section_id: new FormControl(""),
    phone: new FormControl(""),
    status: new FormControl(true),
  });

  getData() {
    this.testService.getTestTypes().subscribe({
      next: (res: any) => {
        this.subject$.next(res.results);
      },
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  testAddFormSubmit() {
    this.addtest(this.testAddForm.value);
  }

  addtest(test) {
    this.AddTestSub = this.testService.addTest(test).subscribe({
      next: (res) => {
        this.onNoClick();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  testEditFormSubmit() {
    this.updatetest(this.testAddForm.value);
  }

  updatetest(testType) {
    this.EditTestSub = this.testService
      .UpdateTest(testType, this.testId)
      .subscribe({
        next: (res) => {
          this.onNoClick();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnInit(): void {
    this.getData()

    this.data$.pipe(filter<TestSection[]>(Boolean)).subscribe((customers) => {
      this.dataSource = customers;
    });
    if (this.testId)
      this.testService.getTestById(this.testId).subscribe({
        next: (res: any) => {
          this.editMode = true;
          console.log(res);
          this.testAddForm.patchValue(res.result);
        },
      });
  }

  static open(dialog: MatDialog, id: string): Observable<boolean> {
    return dialog
      .open(AddTestDialogComponent, { data: id, disableClose: true })
      .afterClosed();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  ngOnDestroy(): void {
    this.AddTestSub?.unsubscribe();
  }

}
