<div
  class="w-full grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 px-4 py-6"
>
  <div class="flex selection max-w-[-webkit-fill-available] py-0">
    <input
      [formControl]="DateFilterCntrl"
      class="border-0"
      matInput
      [matDatepicker]="picker"
      placeholder="Select Date"
    />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <mat-select
    [formControl]="patientCtrl"
    placeholder="Patients"
    [multiple]="true"
    class="selection max-w-[-webkit-fill-available]"
  >
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Find Patients..."
        noEntriesFoundLabel="'No Matches Found'"
        [formControl]="patientFilterCtrl"
      >
        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let patient of filteredPatient | async"
      [value]="patient.patient_code"
    >
      {{ patient?.first_name }} {{ patient?.last_name }}
    </mat-option>
  </mat-select>
  <mat-select
    [formControl]="phoneCtrl"
    placeholder="Phone Number"
    [multiple]="true"
    class="selection max-w-[-webkit-fill-available]"
  >
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Search Phone No..."
        noEntriesFoundLabel="'No Matches Found'"
        [formControl]="phoneFilterCtrl"
      >
        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let patient of filteredPhone | async"
      [value]="patient.phone"
    >
      {{ patient.phone }} - {{ patient?.first_name }} {{ patient?.last_name }}
    </mat-option>
  </mat-select>
  <mat-select
    [formControl]="TestCtrl"
    placeholder="Tests"
    [multiple]="true"
    class="selection max-w-[-webkit-fill-available]"
  >
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Find Tests..."
        noEntriesFoundLabel="'No Matches Found'"
        [formControl]="TestFilterCtrl"
      >
        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let test of filteredTest | async"
      [value]="test.test_name"
    >
      {{ test?.test_name }}
    </mat-option>
  </mat-select>
  <mat-select
    [formControl]="ClinicCtrl"
    placeholder="Clinics"
    [multiple]="true"
    class="selection max-w-[-webkit-fill-available]"
  >
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Find Clinics..."
        noEntriesFoundLabel="'No Matches Found'"
        [formControl]="ClinicFilterCtrl"
      >
        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let clinic of filteredClinic | async"
      [value]="clinic.clinic_name"
    >
      {{ clinic?.clinic_name }}
    </mat-option>
  </mat-select>
  <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
    <button class="btn py-2 btn-secondary max-w-md" (click)="ResetFilters()">
      Reset
    </button>
    <button class="btn py-2 btn-primary max-w-md" (click)="ApplyFilters()">
      Search
    </button>
  </div>
</div>
