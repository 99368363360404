import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TestReportTableComponent } from "../../components/test-report-table/test-report-table.component";
import { AddTestReportDialogComponent } from "../../components/add-test-report-dialog/add-test-report-dialog.component";
import { TestService } from "src/app/modules/settings/services/test.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  templateUrl: "./test-reports.component.html",
  styleUrls: ["./test-reports.component.scss"],
})
export class TestReportsComponent implements OnInit {
  @ViewChild(TestReportTableComponent) child: TestReportTableComponent;
  constructor(
    public dialog: MatDialog,
    private test: TestService,
    private _snackBar: MatSnackBar
  ) {}
  fileName = "";
  ngOnInit(): void {}
  openDialog(): void {
    const dialogRef = this.dialog.open(AddTestReportDialogComponent, {
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.child.getData();
    });
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("file", file);
      this.test.testReportUploadBulk(formData).subscribe({
        next: (res) => {
          this._snackBar.open("Bulk Data Uploaded!");
        },
        error: (error) => {
          this._snackBar.open("Some Error Occured!", "dismiss");
          console.log(error);
        },
      });
    }
  }
}
