import { UserService } from "./../../services/user.service";
import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "byon-add-user-modal",
  templateUrl: "./add-user-modal.component.html",
  styleUrls: ["./add-user-modal.component.scss"],
})
export class AddUserModalComponent implements OnInit, OnDestroy {
  AddUserSub: Subscription | undefined;
  EditUserSub: Subscription | undefined;
  userAddError: string | null = null;
  editMode = false;

  useraddForm = new FormGroup({
    full_name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required]),
    dob: new FormControl("1996-06-02", [Validators.required]),
    password: new FormControl("", [Validators.required]),
    is_active: new FormControl(true, [Validators.required]),
    is_staff: new FormControl(false, [Validators.required]),
    is_superuser: new FormControl(false, [Validators.required]),
  });
  show = true;
  constructor(
    public dialogRef: MatDialogRef<AddUserModalComponent>, // @Inject(MAT_DIALOG_DATA) public data: DialogData
    private userService: UserService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public userId?: number
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  userAddFormSubmit() {
    this.AddUser(this.useraddForm.value);
  }

  AddUser(user) {
    this.AddUserSub = this.userService.addNewUser(user).subscribe({
      next: (res: any) => {
        if (res?.success) {
          this.openSnackBar("User Created!", "Dismiss");
          this.onNoClick();
        } else {
          this.userAddError = res.error;
        }
      },
      error: (err) => {
        this.userAddError = err.error.error;
      },
    });
  }

  userEditFormSubmit() {
    this.updateUser(this.useraddForm.value);
  }

  updateUser(testType) {
    if (testType.password === "") delete testType.password;
    this.EditUserSub = this.userService
      .UpdateTestSection(testType, this.userId)
      .subscribe({
        next: (res) => {
          this.openSnackBar("User Details Updated!", "Dismiss");
          this.onNoClick();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnInit(): void {
    if (this.userId)
      this.userService.getTestTypeById(this.userId).subscribe({
        next: (res: any) => {
          this.editMode = true;
          this.useraddForm.patchValue(res.result);
        },
      });
  }
  static open(dialog: MatDialog, id: string): Observable<boolean> {
    return dialog
      .open(AddUserModalComponent, { data: id, disableClose: true })
      .afterClosed();
  }

  generatePassword() {
    var randomstring = Math.random().toString(36).slice(-10);
    this.useraddForm.controls.password.setValue(randomstring);
    this.openSnackBar("Password Generated!", "Dismiss");
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
  ngOnDestroy(): void {
    this.AddUserSub?.unsubscribe();
  }
}
