<div class="card min-h-full">
  <div class="border-b py-4 px-6 flex items-center">
    <h2 class="m-0 title flex-auto">Test Performance Table</h2>

    <button type="button" class="selection max-w-[-webkit-fill-available] w-40">
      <mat-select
        class="w-full"
        placeholder="Analytic Period"
        name="item"
        [(ngModel)]="AnalysisPeriod"
        (ngModelChange)="getStatsData(AnalysisPeriod)"
      >
        <mat-option *ngFor="let item of items" [value]="item.value">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
    </button>
  </div>

  <div class="pt-3 pb-1 pr-6">
    <div class="overflow-auto p-4">
      <table
        [dataSource]="dataSource"
        class="w-full border rounded"
        mat-table
        matSort
      >
        <ng-container *ngFor="let column of columns">
          <ng-container
            *ngIf="column.type === 'text'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              mat-header-cell
              mat-sort-header
              class="bg-slate-100"
            >
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ NestedObjectParse(column, row) }}
            </td>
          </ng-container>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
      </table>
    </div>
  </div>
</div>
