<div class="flex items-center justify-between">
  <h1 class="text-2xl">Dashboard</h1>
  <button class="mat-button btn-primary" (click)="generateReport()">Generate report</button>
</div>
<div class="row flex w-full flex-col sm:flex-row mt-4 gap-2">
  <vex-info-card
    *ngFor="let item of DashboardCardList"
    [cardTitle]="item.cardName"
    [value]="item.value"
    [link]="item.link"
    [cardImgSrc]="item.icon"
    class="w-full sm:w-1/2 lg:w-1/4 h-[inherit]"
  ></vex-info-card>
</div>
<div class="flex flex-col lg:flex-row gap-2">
  <div class="w-full lg:w-2/3">
    <byon-patient-stats></byon-patient-stats>
  </div>
  <div class="w-full lg:w-1/3">
    <byon-clinic-stats></byon-clinic-stats>
  </div>
</div>
<div class="flex flex-col lg:flex-row gap-2 mt-2">
  <div class="w-full lg:w-1/2">
    <byon-recent-test-table
      [columns]="tableColumns"
      [data]="tableData"
    ></byon-recent-test-table>
  </div>
  <div class="w-full lg:w-1/2"></div>
</div>
