import { Component, OnInit, ViewChild } from '@angular/core';
import { ClinicTableComponent } from '../../components/clinic-table/clinic-table.component';
import { MatDialog } from '@angular/material/dialog';
import { AddClinicModalComponent } from '../../components/add-clinic-modal/add-clinic-modal.component';

@Component({
  templateUrl: './clinics.component.html',
  styleUrls: ['./clinics.component.scss']
})
export class ClinicsComponent implements OnInit {

  @ViewChild(ClinicTableComponent) child: ClinicTableComponent;
  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AddClinicModalComponent, {
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.child.getData();
    });
  }
}
