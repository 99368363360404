<div class="flex items-center justify-between">
  <h1 class="text-2xl">Users</h1>
  <button class="mat-button btn-primary" (click)="openDialog()">
    Add User
  </button>
</div>
<div class="row flex w-full flex-col sm:flex-row mt-4 gap-2"></div>
<div class="w-full">
  <byon-user-table></byon-user-table>
</div>
