import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClinicsComponent } from "./pages/clinics/clinics.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { PatientsComponent } from "./pages/patients/patients.component";
import { TestReportsComponent } from "./pages/test-reports/test-reports.component";
import { TestsComponent } from "./pages/tests/tests.component";
import { UsersComponent } from "./pages/users/users.component";
import { TestReportIssuesComponent } from "./pages/test-report-issues/test-report-issues.component";

const routes: Routes = [
  { path: "", component: DashboardComponent },

  {
    path: "patients",
    component: PatientsComponent,
    children: [],
  },
  {
    path: "users",
    component: UsersComponent,
    children: [],
  },
  {
    path: "clinics",
    component: ClinicsComponent,
    children: [],
  },
  {
    path: "test",
    component: TestsComponent,
    children: [],
  },
  {
    path: "test-reports",
    component: TestReportsComponent,
    children: [],
  },
  {
    path: "test-reports-issues",
    component: TestReportIssuesComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
