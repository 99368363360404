import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import { NgModule } from "@angular/core";
import { AsyncPipe, CommonModule } from "@angular/common";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { InfoCardComponent } from "./components/info-card/info-card.component";
import { PatientStatsComponent } from "./components/patient-stats/patient-stats.component";
import { WidgetLargeChartModule } from "../../../@vex/components/widgets/widget-large-chart/widget-large-chart.module";
import { WidgetLargeGoalChartModule } from "../../../@vex/components/widgets/widget-large-goal-chart/widget-large-goal-chart.module";
import { ChartModule } from "../../../@vex/components/chart/chart.module";
import { ClinicStatsComponent } from "./components/clinic-stats/clinic-stats.component";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PatientsComponent } from "./pages/patients/patients.component";
import { WidgetTableModule } from "src/@vex/components/widgets/widget-table/widget-table.module";
import { RecentTestTableComponent } from "./components/recent-test-table/recent-test-table.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { PatientTableComponent } from "./components/patient-table/patient-table.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { UsersComponent } from "./pages/users/users.component";
import { ClinicsComponent } from "./pages/clinics/clinics.component";
import { ClinicTableComponent } from "./components/clinic-table/clinic-table.component";
import { TestsComponent } from "./pages/tests/tests.component";
import { TestReportsComponent } from "./pages/test-reports/test-reports.component";
import { MatDialogModule } from "@angular/material/dialog";
import { AddPatientDialogComponent } from "./components/add-patient-dialog/add-patient-dialog.component";
import { UserTableComponent } from "./components/user-table/user-table.component";
import { AddUserModalComponent } from "./components/add-user-modal/add-user-modal.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { TestTableComponent } from "./components/test-table/test-table.component";
import { SharedModule } from "../../shared/shared.module";
import { AddClinicModalComponent } from "./components/add-clinic-modal/add-clinic-modal.component";
import { AddTestDialogComponent } from "./components/add-test-dialog/add-test-dialog.component";
import { TestReportTableComponent } from "./components/test-report-table/test-report-table.component";
import { NgxPaginationModule } from "ngx-pagination";
import { TestReportIssuesComponent } from "./pages/test-report-issues/test-report-issues.component";
import { ReportIssueTableComponent } from "./components/report-issue-table/report-issue-table.component";
import { AddTestReportDialogComponent } from "./components/add-test-report-dialog/add-test-report-dialog.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { TestReportFilterComponent } from "./components/test-report-filter/test-report-filter.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

@NgModule({
  declarations: [
    DashboardComponent,
    InfoCardComponent,
    PatientStatsComponent,
    ClinicStatsComponent,
    PatientsComponent,
    RecentTestTableComponent,
    PatientTableComponent,
    UsersComponent,
    ClinicsComponent,
    ClinicTableComponent,
    TestsComponent,
    TestReportsComponent,
    AddPatientDialogComponent,
    UserTableComponent,
    AddUserModalComponent,
    TestTableComponent,
    AddClinicModalComponent,
    AddTestDialogComponent,
    TestReportTableComponent,
    TestReportIssuesComponent,
    ReportIssueTableComponent,
    AddTestReportDialogComponent,
    TestReportFilterComponent,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 900 } },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    AsyncPipe,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    WidgetLargeChartModule,
    WidgetLargeGoalChartModule,
    ChartModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    SharedModule,
    NgxPaginationModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
  ],
})
export class DashboardModule {}
