import { Component, OnInit } from "@angular/core";
import { TableColumn } from "src/@vex/interfaces/table-column.interface";
import { DashboardCardList } from "../../../../configs/dasboard-card.config";
import { Order, tableSalesData, Test } from "../../data/table.data";
import { DashboardService } from "../../services/dashboard.service";
import { HTTPResult } from "src/app/modules/settings/interfaces/test.interface";
import { DashMetrics } from "../../interface/dashboard.iterface";
import { TestService } from "src/app/modules/settings/services/test.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "vex-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  DashboardCardList = DashboardCardList;
  DashMetrics: DashMetrics;
  tableColumns: TableColumn<Test>[] = [
    {
      label: "Patient",
      property: "patient_id.first_name",
      type: "text",
    },
    {
      label: "Date",
      property: "test_date",
      type: "text",
      cssClasses: ["text-secondary"],
    },
    {
      label: "RESULT",
      property: "result",
      type: "text",
    },
    {
      label: "Observation",
      property: "observation",
      type: "text",
    },
    {
      label: "CLINIC",
      property: "clinic_id.clinic_name",
      type: "text",
      cssClasses: ["font-medium"],
    },
  ];
  tableData;
  constructor(
    private dash: DashboardService,
    private _test: TestService,
    private _snackBar: MatSnackBar
  ) {}

  getDashMetrics() {
    this.dash.getDashboardMetrics().subscribe({
      next: (res: HTTPResult<DashMetrics>) => {
        this.DashMetrics = res.result;

        DashboardCardList[0].value = res.result.TotalTests;
        DashboardCardList[1].value = res.result.TotalClinics;
        DashboardCardList[2].value = res.result.TotalPatients;
        DashboardCardList[3].value = res.result.TotalIssues;
      },
      error: (err: any) => {
        console.error(err);
      },
    });
  }

  ngOnInit(): void {
    this.getDashMetrics();
    this.getData();
  }

  getData() {
    this._test
      .getTestReports(1, 10)
      .subscribe((res: any) => (this.tableData = res.results));
  }

  generateReport() {
    this._snackBar.open("Report Currently Unavailable", "dismiss");
  }
}
