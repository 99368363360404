import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDashboardMetrics() {
    return this.http.get(`${environment.apiUrl}dashboard/`);
  }

  getPatientGenderedStats(days) {
    return this.http.get(
      `${environment.apiUrl}dashboard/male-female/days=${days}`
    );
  }
  getTopTestStats(days) {
    return this.http.get(
      `${environment.apiUrl}dashboard/top-tests/days=${days}`
    );
  }
}
