<div class="card">
  <div class="border-b py-4 px-6 flex items-center">
    <h2 class="m-0 title flex-auto">Patient statistics</h2>

    <button type="button" class="selection max-w-[-webkit-fill-available] w-40">
      <mat-select
        class="w-full"
        placeholder="Analytic Period"
        name="item"
        [(ngModel)]="AnalysisPeriod"
        (ngModelChange)="getStatsByGender(AnalysisPeriod)"
      >
        <mat-option *ngFor="let item of items" [value]="item.value">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
    </button>
  </div>

  <div class="pt-3 pb-1 pr-6">
    <vex-chart [options]="options" [series]="userSessionsSeries"></vex-chart>
  </div>
</div>
