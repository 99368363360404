import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { ClinicService } from '../../services/clinic.service';

@Component({
  selector: 'byon-add-clinic-modal',
  templateUrl: './add-clinic-modal.component.html',
  styleUrls: ['./add-clinic-modal.component.scss']
})
export class AddClinicModalComponent implements OnInit {

  AddClinicSub: Subscription | undefined;
  EditClinicSub: Subscription | undefined;
  ClinicAddError: string | null = null;
  editMode = false;

  constructor(
    public dialogRef: MatDialogRef<AddClinicModalComponent>, // @Inject(MAT_DIALOG_DATA) public data: DialogData
    private clinicService: ClinicService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public ClinicId?: number
  ) { }


  clinicAddForm = new FormGroup({
    clinic_name: new FormControl(""),
    location: new FormControl(""),
    phone: new FormControl(""),
    status: new FormControl(true),
  });



  onNoClick(): void {
    this.dialogRef.close();
  }

  clinicAddFormSubmit() {
    this.addClinic(this.clinicAddForm.value);
  }

  addClinic(Clinic) {
    this.AddClinicSub = this.clinicService.addClinic(Clinic).subscribe({
      next: (res) => {
        this.onNoClick();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  clinicEditFormSubmit() {
    this.updateClinic(this.clinicAddForm.value);
  }

  updateClinic(testType) {
    this.EditClinicSub = this.clinicService
      .updateClinic(testType, this.ClinicId)
      .subscribe({
        next: (res) => {
          this.onNoClick();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnInit(): void {
    if (this.ClinicId)
      this.clinicService.getClinicsById(this.ClinicId).subscribe({
        next: (res: any) => {
          this.editMode = true;
          console.log(res);
          this.clinicAddForm.patchValue(res.result);
        },
      });
  }

  static open(dialog: MatDialog, id: string): Observable<boolean> {
    return dialog
      .open(AddClinicModalComponent, { data: id, disableClose: true })
      .afterClosed();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  ngOnDestroy(): void {
    this.AddClinicSub?.unsubscribe();
  }
}
