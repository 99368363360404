<div
  class="min-h-full flex flex-col gap-3 lg:gap-6 items-start justify-center xl:ml-20"
>
  <h1 class="text-xl font-bold">Welcome Back!</h1>
  <p class="text-xl">Sign In to continue to Byoncare.</p>
  <form
    [formGroup]="loginForm"
    (ngSubmit)="onSubmit()"
    class="flex flex-col gap-4 w-full md:w-[70%] xl:w-[55%] xxxl:w-1/4"
  >
    <div class="fonrm-control">
      <label class="block text-xs mb-2" for="">Email</label>
      <input
        type="email"
        placeholder="name@example.com"
        formControlName="email"
        class="px-3 py-2 border border-zinc-400 rounded focus:outline-rose-200 w-[-webkit-fill-available]"
        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
      />
    </div>
    <div class="form-control">
      <label class="block text-xs mb-2" for="">Password</label>
      <input
        type="password"
        placeholder="password"
        formControlName="password"
        class="px-3 py-2 border border-zinc-400 rounded focus:outline-rose-200 w-[-webkit-fill-available]"
        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      />
    </div>
    <div class="form-control flex justify-between flex-wrap">
      <div class="form-control flex gap-2 justify-start">
        <mat-checkbox class="example-margin">Remember me</mat-checkbox>
      </div>
      <p class="text-xs cursor-pointer">Forgot password?</p>
    </div>
    <div
      class="w-full border rounded-md border-red-300 bg-red-100 text-red-300 my-2 py-2 px-3"
      *ngIf="loginError"
    >
      {{ loginError }}
    </div>
    <button
      class="px-3 py-3 rounded-md btn-primary w-full select-none flex items-center justify-center gap-2"
      type="submit"
    >
      <mat-spinner
        [color]="'accent'"
        [diameter]="20"
        *ngIf="submitted"
      ></mat-spinner>
      {{ submitted ? "Signing You In" : " Sign In" }}
    </button>
  </form>
</div>
