import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ReplaySubject, Subject, map, takeUntil } from "rxjs";
import { Clinic, Patient } from "../../interfaces/customer.model";
import { FormControl } from "@angular/forms";
import { PatientService } from "../../services/patient.service";
import {
  HTTPResultArrayPaginated,
  Test,
} from "src/app/modules/settings/interfaces/test.interface";
import { ClinicService } from "../../services/clinic.service";
import { TestService } from "src/app/modules/settings/services/test.service";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: "byon-test-report-filter",
  templateUrl: "./test-report-filter.component.html",
  styleUrls: ["./test-report-filter.component.scss"],
})
export class TestReportFilterComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public patientCtrl: FormControl<Patient[]> = new FormControl<Patient[]>([]);
  public patientFilterCtrl: FormControl<string> = new FormControl<string>("");
  protected filteredPatient: ReplaySubject<Patient[]> = new ReplaySubject<
    Patient[]
  >(1);
  public phoneCtrl: FormControl<Patient[]> = new FormControl<Patient[]>([]);
  public phoneFilterCtrl: FormControl<string> = new FormControl<string>("");
  protected filteredPhone: ReplaySubject<Patient[]> = new ReplaySubject<
    Patient[]
  >(1);
  public TestCtrl: FormControl<Test[]> = new FormControl<Test[]>([]);
  public TestFilterCtrl: FormControl<string> = new FormControl<string>("");
  protected filteredTest: ReplaySubject<Test[]> = new ReplaySubject<Test[]>(1);

  public ClinicCtrl: FormControl<Clinic[]> = new FormControl<Clinic[]>([]);
  public ClinicFilterCtrl: FormControl<string> = new FormControl<string>("");
  protected filteredClinic: ReplaySubject<Clinic[]> = new ReplaySubject<
    Clinic[]
  >(1);

  public DateFilterCntrl: FormControl<any> = new FormControl<any>(null);
  protected _onDestroy = new Subject<void>();

  constructor(
    private patient: PatientService,
    private Clinic: ClinicService,
    private test: TestService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getPatients();
    this.getTest();
    this.getClinic();
    this.getPatientPhones();
    this.patientFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPatients();
      });
    this.phoneFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPatientPhones();
      });
    this.TestFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTest();
      });
    this.ClinicFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterClinic();
      });
  }
  ngAfterViewInit(): void {
    this.setFilterFromRouteParams();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setFilterFromRouteParams() {
    this.route.queryParams.subscribe((params: Params) => {
      this.patientCtrl.patchValue(params.patient_ids?.split(",") || null);
      this.DateFilterCntrl.patchValue(
        this.DateToString(params.test_date) || null
      );
      this.TestCtrl.patchValue(params.test_names?.split(",") || null);
      this.ClinicCtrl.patchValue(params.clinic_names?.split(",") || null);
      this.phoneCtrl.patchValue(params.patient_phones?.split(",") || null);
    });
  }

  // * Patients
  filterPatients() {
    let search = this.patientFilterCtrl.value;
    if (!search) {
      this.getPatients();
      return;
    } else {
      search = search.toLowerCase();
      this.getPatients(search);
    }
  }

  getPatients(search?) {
    this.patient
      .getAllPatients(1, 10, search || null)
      .subscribe((res: HTTPResultArrayPaginated<Patient>) => {
        this.filteredPatient.next(res.results);
      });
  }

  // * Test
  filterTest() {
    let search = this.TestFilterCtrl.value;
    if (!search) {
      this.getTest();
      return;
    } else {
      search = search.toLowerCase();
      this.getTest(search);
    }
  }

  getTest(search?) {
    this.test.getTest(1, 10, search).subscribe((res: Test[]) => {
      this.filteredTest.next(res);
    });
  }

  // * Clinic
  filterClinic() {
    let search = this.ClinicFilterCtrl.value;
    if (!search) {
      this.getClinic();
      return;
    } else {
      search = search.toLowerCase();
      this.getClinic(search);
    }
  }

  getClinic(search?) {
    this.Clinic.getAllClinics(search).subscribe(
      (res: HTTPResultArrayPaginated<Clinic>) => {
        this.filteredClinic.next(res.results);
      }
    );
  }

  filterPatientPhones() {
    let search = this.phoneFilterCtrl.value;
    if (!search) {
      this.getPatientPhones();
      return;
    } else {
      this.getPatientPhones(search);
    }
  }

  getPatientPhones(search?) {
    this.patient
      .getAllPatientsByPhone(1, 10, search || null)
      .subscribe((res: HTTPResultArrayPaginated<Patient>) => {
        this.filteredPhone.next(res.results);
      });
  }

  // ?? Apply Filter On Table
  ApplyFilters() {
    this.router.navigate(["/test-reports"], {
      relativeTo: this.route,
      queryParams: {
        test_date: this.DateToString(this.DateFilterCntrl.value) || "",
        clinic_names: this.ClinicCtrl.value?.toString() || "",
        patient_phones: this.phoneCtrl.value?.toString(),
        patient_ids: this.patientCtrl.value?.toString() || "",
        test_names: this.TestCtrl.value?.toString() || "",
        page: "",
      },
      queryParamsHandling: "merge",
    });
  }

  DateToString(date) {
    let dateReturn = date
      ? date === ""
        ? ""
        : typeof date === "string"
        ? date
        : date.format("YYYY-MM-DD")?.toString()
      : "";

    return dateReturn;
  }

  ResetFilters() {
    this.patientCtrl.patchValue(null);
    this.DateFilterCntrl.patchValue(null);
    this.TestCtrl.patchValue(null);
    this.ClinicCtrl.patchValue(null);
    this.phoneCtrl.patchValue(null);

    this.router.navigate(["/test-reports"]);
  }
}
