<div class="bg-white rounded px-4 py-8 border-l-2 mb-3 flex justify-start cursor-pointer info-card" [routerLink]="linkPrefix(link)">
  <div class="media mr-3">
    <img [src]="cardImgSrc" alt="">
  </div>

  <div class="flex items-start flex-col justify-center">
    <h4 class="">{{cardTitle}}</h4>
    <p class="text-3xl font-bold">{{value}}</p>
  </div>
</div>
