import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  visible: boolean = false;
  loginForm: any = FormGroup;
  loginError: string | null = null;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService
  ) {}

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
  }

  toggle() {
    this.visible = !this.visible;
  }

  onSubmit() {
    this.loginError = null;
    this.submitted = true;
    this.loginSubmit(this.loginForm.value);
  }

  loginSubmit(login) {
    this.auth.login(login).subscribe({
      next: (res: any) => {
        console.log(res);
        if (res?.status) {
          this.auth.loginNow(res?.result.access_token);
          setTimeout(() => {
            this.router.navigateByUrl("/");
          }, 0);
        } else {
          console.log(res.error);
          this.loginError = res.error;
          this.submitted = false;
        }
      },
      error: (err: any) => {
        this.loginError = err.error.error;
        this.submitted = false;
      },
    });
  }
}
