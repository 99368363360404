import { ClinicService } from "./../../services/clinic.service";
import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from "@angular/material/form-field";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { untilDestroyed } from "@ngneat/until-destroy";
import { filter, Observable, of, ReplaySubject } from "rxjs";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { stagger40ms } from "src/@vex/animations/stagger.animation";
import { TableColumn } from "src/@vex/interfaces/table-column.interface";
import { aioTableData, statusList } from "../../data/patient-table.data";
import { Clinic } from "../../interfaces/customer.model";
import { AddClinicModalComponent } from "../add-clinic-modal/add-clinic-modal.component";

@Component({
  selector: "byon-clinic-table",
  templateUrl: "./clinic-table.component.html",
  styleUrls: ["./clinic-table.component.scss"],
  animations: [fadeInUp400ms, stagger40ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "standard",
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class ClinicTableComponent implements OnInit {
  layoutCtrl = new UntypedFormControl("fullwidth");

  subject$: ReplaySubject<Clinic[]> = new ReplaySubject<Clinic[]>(1);
  data$: Observable<Clinic[]> = this.subject$.asObservable();
  Clinics: Clinic[];
  @Output() StatusFilterEmitter = new EventEmitter<number>();
  StatusFilter = null;

  items = [
    {
      value: 1,
      viewValue: "Closed",
    },
    {
      value: 2,
      viewValue: "Idle",
    },
    {
      value: 3,
      viewValue: "Waiting",
    },
  ];
  @Input()
  columns: TableColumn<Clinic>[] = [
    {
      label: "Checkbox",
      property: "checkbox",
      type: "checkbox",
      visible: false,
    },

    {
      label: "No.",
      property: "id",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Name",
      property: "clinic_name",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },

    {
      label: "Location",
      property: "location",
      type: "text",
      visible: true,
      cssClasses: ["text-secondary", "font-medium"],
    },
    {
      label: "Phone",
      property: "phone",
      type: "text",
      visible: true,
      cssClasses: ["text-secondary", "font-medium"],
    },
    {
      label: "Status",
      property: "status",
      type: "badge",
      visible: true,
      cssClasses: ["text-secondary", "font-medium"],
    },
    { label: "Actions", property: "actions", type: "button", visible: true },
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<Clinic> | null;
  selection = new SelectionModel<Clinic>(true, []);
  searchCtrl = new UntypedFormControl();

  status = statusList;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private clinicService: ClinicService
  ) { }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getData() {
    this.clinicService.getAllClinics().subscribe({
      next: (res: any) => {
        this.subject$.next(res.results);
      },
    });
  }

  ngOnInit() {
    this.getData();
    this.dataSource = new MatTableDataSource();

    this.data$.pipe(filter<Clinic[]>(Boolean)).subscribe((Clinics) => {
      this.Clinics = Clinics;
      this.dataSource.data = Clinics;
    });

    this.searchCtrl.valueChanges
      .subscribe((value) => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "status":
          return item.status;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  deleteClinic(Clinic: Clinic) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    this.Clinics.splice(
      this.Clinics.findIndex(
        (existingClinic) => existingClinic.id === Clinic.id
      ),
      1
    );
    this.selection.deselect(Clinic);
    this.subject$.next(this.Clinics);
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: Clinic) {
    const index = this.Clinics.findIndex((c) => c === row);
    this.Clinics[index].status = change.value;
    this.subject$.next(this.Clinics);
  }


  openDialog(id: number): void {
    const dialogRef = this.dialog.open(AddClinicModalComponent, {
      data: id,
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }
}
