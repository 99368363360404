import { query } from "@angular/animations";
import { Params, Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { AddPatientDialogComponent } from "./../add-patient-dialog/add-patient-dialog.component";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Observable, of, map, ReplaySubject } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { Patient } from "./../../interfaces/customer.model";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { TableColumn } from "../../../../../@vex/interfaces/table-column.interface";
import { statusList } from "../../data/patient-table.data";
import { SelectionModel } from "@angular/cdk/collections";
import { fadeInUp400ms } from "../../../../..//@vex/animations/fade-in-up.animation";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import { stagger40ms } from "../../../../../@vex/animations/stagger.animation";
import { UntypedFormControl } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MatSelectChange } from "@angular/material/select";
import { PatientService } from "../../services/patient.service";

@UntilDestroy()
@Component({
  selector: "byon-patient-table",
  templateUrl: "./patient-table.component.html",
  styleUrls: ["./patient-table.component.scss"],
  animations: [fadeInUp400ms, stagger40ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "standard",
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class PatientTableComponent implements OnInit, AfterViewInit {
  layoutCtrl = new UntypedFormControl("fullwidth");

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Patient[]> = new ReplaySubject<Patient[]>(1);
  data$: Observable<Patient[]> = this.subject$.asObservable();
  Patients: Patient[];
  @Output() StatusFilterEmitter = new EventEmitter<number>();
  StatusFilter = null;
  @Input()
  columns: TableColumn<Patient>[] = [
    {
      label: "Patient ID",
      property: "patient_code",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Name",
      property: "first_name",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    { label: "Last Name", property: "lastName", type: "text", visible: false },
    {
      label: "Phone",
      property: "phone",
      type: "text",
      visible: true,
      cssClasses: ["text-secondary", "font-medium"],
    },
    {
      label: "Age",
      property: "age",
      type: "text",
      visible: true,
      cssClasses: ["text-secondary", "font-medium"],
    },
    { label: "Status", property: "status", type: "badge", visible: true },
    { label: "Actions", property: "actions", type: "button", visible: true },
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<Patient> | null;
  selection = new SelectionModel<Patient>(true, []);
  searchCtrl = new UntypedFormControl();
  dataSource$ = new Observable<Patient[]>();
  status = statusList;
  length;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  params: Params;

  constructor(
    private dialog: MatDialog,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.data$.pipe(filter<Patient[]>(Boolean)).subscribe((Patients) => {
      this.Patients = Patients;
      this.dataSource.data = Patients;
    });
    this.getData();
    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => {
        console.log(value, value.length);
        let params;
        if (value.length === 0) params = { query: value };
        else params = { query: value, page: 1 };
        this.router.navigate(["/patients"], {
          relativeTo: this.route,
          queryParams: params,
          queryParamsHandling: "merge",
        });
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "status":
          return item.status;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe({
      next: () => {
        this.router.navigate(["/patients"], {
          relativeTo: this.route,
          queryParams: { page: this.paginator.pageIndex + 1 },
          queryParamsHandling: "merge",
        });
      },
    });
  }

  getData() {
    this.dataSource$ = this.route.queryParams.pipe(
      switchMap((params: Params) => {
        console.log(params);
        this.paginator.pageIndex = params.page - 1 || 0;
        if (this.searchCtrl.value !== params.query)
          this.searchCtrl.patchValue(params.query || "");
        return this.patientService
          .getAllPatients(
            this.paginator.pageIndex + 1,
            this.pageSize,
            this.searchCtrl.value
          )
          .pipe(
            map((res: any) => {
              this.length = res.count;
              return res.results;
            }),
            catchError(() => {
              this.length = 0;
              return of(null);
            })
          );
      })
    );
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: Patient) {
    const index = this.Patients.findIndex((c) => c === row);
    this.Patients[index].status = change.value;
    this.subject$.next(this.Patients);
  }

  deleteUser(id: number) {
    this.patientService.deletePatient(id).subscribe({
      next: (res) => {
        this.getData();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  openDialog(id: number): void {
    const dialogRef = this.dialog.open(AddPatientDialogComponent, {
      data: id,
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }
}
