import { Component, OnInit } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/@vex/config/config.service";

@Component({
  selector: "byon-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent implements OnInit {
  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl)
  );
  constructor(private configService: ConfigService) {}

  ngOnInit(): void {}
}
