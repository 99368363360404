import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { CustomLayoutComponent } from "./layouts/dash-layout/custom-layout.component";

const routes: Routes = [
  {
    path: "",
    component: CustomLayoutComponent,
    loadChildren: () =>
      import("./modules/dashboard/dashboard-routing.module").then(
        (m) => m.DashboardRoutingModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./modules/authentication/authentication-routing.module").then(
        (m) => m.AuthenticationRoutingModule
      ),
  },
   
  {
    path: "settings",
    component: CustomLayoutComponent,
    loadChildren: () =>
      import("./modules/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
