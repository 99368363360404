import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './test-report-issues.component.html',
  styleUrls: ['./test-report-issues.component.scss']
})
export class TestReportIssuesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
