import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { Patient, PatientNew } from "../interfaces/customer.model";
import { environment } from "src/environments/environment";
import { HTTPResultArrayPaginated } from "./../../settings/interfaces/test.interface";
@Injectable({
  providedIn: "root",
})
export class PatientService {
  constructor(private httpClient: HttpClient) {}

  getAllPatients(page, pageSize, name?) {
    return this.httpClient.get<HTTPResultArrayPaginated<Patient>>(
      `${environment.apiUrl}patients/list/?page=${page}&first_name=${
        name || ""
      }`
    );
  }
  getAllPatientsByPhone(page, pageSize, phone?) {
    return this.httpClient.get<HTTPResultArrayPaginated<Patient>>(
      `${environment.apiUrl}patients/list/?page=${page}&phone=${phone || ""}`
    );
  }

  addPatient(patient: PatientNew) {
    return this.httpClient.post(`${environment.apiUrl}patients/`, patient);
  }

  updatePatient(patient: PatientNew, id: number) {
    return this.httpClient.put(`${environment.apiUrl}patients/${id}/`, patient);
  }

  getPatient(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}patients/${patientId}/`);
  }

  deletePatient(patientId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}patients/${patientId}/`
    );
  }
}
