import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, ReplaySubject, Subscription, filter } from "rxjs";
import {
  HTTPResultArrayPaginated,
  TestSection,
} from "src/app/modules/settings/interfaces/test.interface";
import { TestService } from "src/app/modules/settings/services/test.service";
import { ClinicService } from "../../services/clinic.service";
import { Clinic, Patient } from "../../interfaces/customer.model";
import { PatientService } from "../../services/patient.service";
import { DateAdapter } from "@angular/material/core";
import { TestReport } from "../../interfaces/test-report.interface";

@Component({
  selector: "byon-add-test-report-dialog",
  templateUrl: "./add-test-report-dialog.component.html",
  styleUrls: ["./add-test-report-dialog.component.scss"],
})
export class AddTestReportDialogComponent implements OnInit {
  AddTestSub: Subscription | undefined;
  EditTestSub: Subscription | undefined;
  TestAddError: string | null = null;
  editMode = false;
  subject$: ReplaySubject<TestSection[]> = new ReplaySubject<TestSection[]>(1);
  ClinicSubject$: ReplaySubject<Clinic[]> = new ReplaySubject<Clinic[]>(1);
  data$: Observable<TestSection[]> = this.subject$.asObservable();
  Clinicdata$: Observable<Clinic[]> = this.ClinicSubject$.asObservable();
  dataSource;
  ClinicData;
  PatientData;
  currentPatient: Patient;
  currentResult;

  public patientFilterCtrl: FormControl<string> = new FormControl<string>("");
  protected filteredPatient: ReplaySubject<Patient[]> = new ReplaySubject<
    Patient[]
  >(1);
  @Output() update = new EventEmitter<Boolean>(false);

  constructor(
    private dateAdapter: DateAdapter<Date>,
    public dialogRef: MatDialogRef<AddTestReportDialogComponent>, // @Inject(MAT_DIALOG_DATA) public data: DialogData
    private testService: TestService,
    private clinic: ClinicService,
    private patient: PatientService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public testReportId?: number
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy}
  }

  testAddForm = new FormGroup({
    FirstName: new FormControl(""),
    LastName: new FormControl(""),
    Age: new FormControl(""),
    Result: new FormControl(""),
    Gender: new FormControl(""),
    test_date: new FormControl(),
    ClinicName: new FormControl(""),
    Test: new FormControl(""),
    Telephone: new FormControl(""
    ),
  });

  // * Patients
  filterPatients() {
    let search = this.patientFilterCtrl.value;
    if (!search) {
      this.getPatients();
      return;
    } else {
      search = search.toLowerCase();
      this.getPatients(search);
    }
  }

  getPatients(search?) {
    this.patient
      .getAllPatients(1, 10, search || null)
      .subscribe((res: HTTPResultArrayPaginated<Patient>) => {
        this.filteredPatient.next(res.results);
      });
  }

  getData() {
    this.testService.getTest(1, 10).subscribe({
      next: (res: any) => {
        this.subject$.next(res);
      },
    });
  }
  getClinicData() {
    this.clinic.getAllClinics().subscribe({
      next: (res: any) => {
        this.ClinicSubject$.next(res.results);
      },
    });
  }
  getPatientData() {
    this.patient.getAllPatients(1, 10).subscribe({
      next: (res: any) => {
        this.PatientData = res.results;
      },
    });
  }

  changePatientDetails() {
    let patient = {
      FirstName: this.currentPatient.first_name,
      LastName: this.currentPatient.last_name,
      Age: this.currentPatient.age?.toString(),
      Gender: this.currentPatient.gender?.toString(),
      Telephone: this.currentPatient.phone?.toString(),
    };
    this.testAddForm.patchValue(patient);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  testAddFormSubmit() {
    this.addtest(this.testAddForm.value);
  }

  changeTestDetails() {}

  addtest(test) {
    test.test_date = this.testAddForm.value.test_date?.format("YYYY-MM-DD");
    this.AddTestSub = this.testService.addPatientTest(test).subscribe({
      next: (res) => {
        this.onNoClick();
        this._snackBar.open("Data Added");
      },
      error: (err) => {
        console.log(err);
        this._snackBar.open("Check feilds!");
      },
    });
  }

  testEditFormSubmit() {
    this.updatetest();
  }

  updatetest() {
    const testType: TestReport = this.currentResult;
    testType.patient_id.first_name =
      this.testAddForm.controls["FirstName"].value;
    testType.patient_id.last_name = this.testAddForm.controls["LastName"].value;
    testType.patient_id.age = parseInt(this.testAddForm.controls["Age"].value);
    testType.patient_id.gender = this.testAddForm.controls["Gender"].value;
    testType.patient_id.phone = this.testAddForm.controls["Telephone"].value;
    testType.clinic_id.clinic_name =
      this.testAddForm.controls["ClinicName"].value;
    testType.test_id.test_name = this.testAddForm.controls["Test"].value;
    if (this.testAddForm.controls["test_date"].value.toString().length !== 10) {
      testType.test_date =
        this.testAddForm.controls["test_date"].value.format("YYYY-MM-DD");
    } else testType.test_date = this.testAddForm.controls["test_date"].value;
    testType.result = this.testAddForm.controls["Result"].value;

    this.EditTestSub = this.testService
      .editTestReportById(this.testReportId, testType)
      .subscribe({
        next: (res) => {
          this.onNoClick();
          this._snackBar.open("Data Updated");
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnInit(): void {
    this.getData();
    this.getClinicData();
    this.getPatients();
    this.data$.pipe(filter<TestSection[]>(Boolean)).subscribe((customers) => {
      this.dataSource = customers;
    });

    this.patientFilterCtrl.valueChanges.subscribe({
      next: (res) => {
        this.filterPatients();
      },
    });
    this.Clinicdata$.pipe(filter<Clinic[]>(Boolean)).subscribe((clinic) => {
      this.ClinicData = clinic;
    });
    if (this.testReportId)
      this.testService.getTestReportById(this.testReportId).subscribe({
        next: (res: any) => {
          this.currentResult = res.result;
          this.editMode = true;
          this.currentPatient = res.result.patient_id;
          this.changePatientDetails();
          this.testAddForm.patchValue({
            Result: res.result.result,
            test_date: res.result.test_date,
            ClinicName: res.result.clinic_id.clinic_name,
            Test: res.result.test_id.test_name,
          });
        },
      });
  }

  static open(dialog: MatDialog, id: string): Observable<boolean> {
    return dialog
      .open(AddTestReportDialogComponent, { data: id, disableClose: true })
      .afterClosed();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  ngOnDestroy(): void {
    this.AddTestSub?.unsubscribe();
  }
}
