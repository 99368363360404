import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VexModule } from "../@vex/vex.module";
import { HttpClientModule } from "@angular/common/http";
import { CustomLayoutModule } from "./layouts/custom-layout.module";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { AuthenticationModule } from "./modules/authentication/authentication.module";
import { JwtModule, JwtModuleOptions } from "@auth0/angular-jwt";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { SharedModule } from "./shared/shared.module"; 
export function tokenGetter() {
  return localStorage.getItem("access_token");
}
const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: tokenGetter,
    allowedDomains: ["example.com"],
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot(JWT_Module_Options),
    // Vex
    VexModule,
    CustomLayoutModule,
    DashboardModule,
    AuthenticationModule,
    SharedModule,  MatMomentDateModule, 
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
