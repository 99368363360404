import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "byon-status-indicator",
  templateUrl: "./status-indicator.component.html",
  styleUrls: ["./status-indicator.component.scss"],
})
export class StatusIndicatorComponent implements OnInit {
  @Input() statusName = "Active";
  @Input() color: "green" | "red" | "yellow" | "blue" = "green";

  colorVariants = {
    green: "border-green-500 text-green-600 bg-green-100",
    red: "border-red-500 text-red-600 bg-red-100",
    yellow: "border-yellow-500 text-yellow-600 bg-yellow-100",
    blue: "border-blue-500 text-blue-600 bg-blue-100",
  };
  constructor() {}

  ngOnInit(): void {}
}
