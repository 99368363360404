import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table"; 
import { Observable, ReplaySubject, filter } from "rxjs";
import { TestService } from "src/app/modules/settings/services/test.service";
import { statusList } from "../../data/patient-table.data";
import { TableColumn } from "src/@vex/interfaces/table-column.interface";
import { HTTPResult, HTTPResultArray, Test } from "src/app/modules/settings/interfaces/test.interface";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { stagger40ms } from "src/@vex/animations/stagger.animation";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import { AddTestDialogComponent } from "../add-test-dialog/add-test-dialog.component";

@Component({
  selector: "byon-test-table",
  templateUrl: "./test-table.component.html",
  styleUrls: ["./test-table.component.scss"],
  animations: [fadeInUp400ms, stagger40ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "standard",
      } as unknown as MatFormFieldDefaultOptions,
    },
  ],
})
export class TestTableComponent implements OnInit {
  layoutCtrl = new UntypedFormControl("fullwidth");

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Test[]> = new ReplaySubject<Test[]>(1);
  data$: Observable<Test[]> = this.subject$.asObservable();
  customers: Test[];

  allTests: [];
  @Output() StatusFilterEmitter = new EventEmitter<number>();
  StatusFilter = null;

  @Input()
  columns: TableColumn<Test>[] = [
    {
      label: "No.",
      property: "id",
      type: "id",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Test Section",
      property: "test_section_name",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Test",
      property: "test_name",
      type: "text",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "references",
      property: "references",
      type: "badge",
      visible: true,
      cssClasses: ["font-medium"],
    },
    {
      label: "Date",
      property: "updated_at",
      type: "date",
      visible: false,
      cssClasses: ["text-secondary", "font-medium"],
    },
    { label: "Status", property: "status", type: "badge", visible: true },
    { label: "Actions", property: "actions", type: "button", visible: true },
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<Test> | null;
  selection = new SelectionModel<Test>(true, []);
  searchCtrl = new UntypedFormControl();

  status = statusList;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialog: MatDialog, private testService: TestService) { }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  ngOnInit() {
    this.getData();
    this.dataSource = new MatTableDataSource();

    this.data$.pipe(filter<Test[]>(Boolean)).subscribe((customers) => {
      this.customers = customers;
      this.dataSource.data = customers;
    });

    this.searchCtrl.valueChanges
      .subscribe((value) => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getData() {
    this.testService.getTest(1,this.pageSize).subscribe({
      next: (res: any) => {
        this.subject$.next(res);
      },
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: Test) {
    const index = this.customers.findIndex((c) => c === row);
    // this.customers[index].status = change.value;
    this.subject$.next(this.customers);
  }

  deleteTest(id: number) {
    this.testService.deleteTest(id).subscribe({
      next: (res) => {
        this.getData();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  openDialog(id: number): void {
    const dialogRef = this.dialog.open(AddTestDialogComponent, {
      data: id,
      backdropClass: "bdrop",
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }
}
